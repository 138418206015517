import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { dateFormat } from '../utils/formatter'

import {
  IconButton,
  Typography,
  Grid,
  TextField,
  Paper,
  InputAdornment,
  Button
} from '@material-ui/core'
import LambdaFetch from '../functions/FetchFromLambda'
import ReactTable from '../components/ReactTable'
import Autocomplete from '@material-ui/lab/Autocomplete'
import useDateRangeCol from '../hooks/useDateRangeCol';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  }
}))

export default function MetadataHelp (props) {
  const classes = useStyles()

  const [state, setstate] = useState({
    isLoading: false,
    categories: [],
    data: null,
    tableKey: null
  })
  const [showTable, setShowTable] = useState(false)
  const [category, setCategory] = useState(null)

  const { credentials } = props.fetchInitialData

  useEffect(() => {
    async function fetchMyAPI () {
      fetchMetaHrd()
    }
    fetchMyAPI()
  }, [])

  const fetchMetaHrd = async () => {
    try {
      const resp = await LambdaFetch(
        'meta-data',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-meta-data-hdr'
        }),
        '',
        credentials
      )

      const cat = resp?.data?.categories.sort((a, b) => {
        if (a.DESCRIPTION < b.DESCRIPTION) return -1;
        if (a.DESCRIPTION > b.DESCRIPTION) return 1;
        return 0; // Equal descriptions
      });

      setstate({
        ...state,
        categories: cat
      })
    } catch (e) {
      console.log(e)
    }
  }
  const onTagChange = (event, value) => {
    if (value) {
      setCategory(value)
      fetchMetadataDtl(value.NAME)
    } else {
      setCategory(null)
    }
  }
  const handleSubmit = async e => {
    e.preventDefault()
    setShowTable(true)
  }

  const fetchMetadataDtl = async category => {
    setstate({...state, isLoading: true})
    try {
      const resp = await LambdaFetch(
        'meta-data',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'get-meta-data-dtl',
          category
        }),
        '',
        credentials
      )

      setstate({
        ...state,
        data: resp.data.metadataDtl,
        isLoading: false,
        tableKey: category
      })
    } catch (e) {
      console.log(e)
      setstate({ ...state, isLoading: false })
    }
  }
  const PaintCols = (category) => {
    const startDate = useDateRangeCol('START_DATE', 'Start Date');
    const endDate = useDateRangeCol('END_DATE', 'End Date');
    let cols = [
      {
        accessorKey: 'CODE',
        header: 'CODE',
        filterFn: 'contains'
      },
      {
        accessorKey: 'DESCRIPTION',
        header: 'DESCRIPTION',
        filterFn: 'contains'
      },
      startDate,
      endDate,
      {
        accessorKey: 'ERP_STATUS',
        header: 'STATUS',
        filterFn: 'contains'
      }
    ]

    // if (category.KEY1) {
    //   cols.push({
    //     accessorKey: 'KEY1',
    //     header: category.KEY1,
    //     filterFn: 'contains'
    //   })
    // }
    // if (category.KEY2) {
    //   cols.push({
    //     accessorKey: 'KEY2',
    //     header: category.KEY2,
    //     filterFn: 'contains'
    //   })
    // }
    // if (category.KEY3) {
    //   cols.push({
    //     accessorKey: 'KEY3',
    //     header: category.KEY3,
    //     filterFn: 'contains'
    //   })
    // }
    return cols
  }
const metaCols = PaintCols(category)

if(category) {
if (category.KEY1) {
  metaCols.push({
      accessorKey: 'KEY1',
      header: category.KEY1,
      filterFn: 'contains'
    })
  }
  if (category.KEY2) {
    metaCols.push({
      accessorKey: 'KEY2',
      header: category.KEY2,
      filterFn: 'contains'
    })
  }
  if (category.KEY3) {
    metaCols.push({
      accessorKey: 'KEY3',
      header: category.KEY3,
      filterFn: 'contains'
    })
  }
}
console.log(state.categories)
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        Metadata
      </Typography>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id='cateogry'
              options={state.categories}
              getOptionLabel={option => option.DESCRIPTION}
              filterSelectedOptions
              autoHighlight
              autoSelect
              onChange={(event, values) => onTagChange(event, values)}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField
                  {...params}
                  margin='dense'
                  label='Category'
                  variant='outlined'
                />
              )}
            />
            <div style={{ margin: '1rem 0' }}>
              {!!category && (
                <ReactTable
                isLoading={state.isLoading}
                  key={state.tableKey}
                  cols={metaCols}
                  data={state.data}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
